<div class="p-7 w-full h-full">
  <div class="flex flex-col md:flex-row">
    <div class="flex-1">
      <form [formGroup]="inputForm">
        <div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Pozíció</mat-label>

            <textarea
              matInput [placeholder]="placeholders.position" rows="2" [formControl]="inputForm.controls.position"
            >
            </textarea>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>A cégről</mat-label>

            <textarea
              matInput [placeholder]="placeholders.company" rows="3" [formControl]="inputForm.controls.company"
            >
            </textarea>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>A pozícióról</mat-label>

            <textarea
              matInput [placeholder]="placeholders.description" rows="3" [formControl]="inputForm.controls.description"
            >
            </textarea>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Elvárások</mat-label>

            <textarea
              matInput [placeholder]="placeholders.requirements" rows="3"
              [formControl]="inputForm.controls.requirements"
            >
            </textarea>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Amit kínálunk</mat-label>

            <textarea
              matInput [placeholder]="placeholders.benefits" rows="3" [formControl]="inputForm.controls.benefits"
            >
            </textarea>
          </mat-form-field>
        </div>
      </form>
    </div>


    <div class="p-3 flex flex-row items-center justify-center">
      <cool-loading-button color="accent" [disabled]="!inputForm.valid || isLoading()" [clickHandler]="generateResultAsync.bind(this)">
        Készítsd el az álláshirdetést!
      </cool-loading-button>
    </div>

    <div class="flex-1">
      <mat-form-field appearance="outline" class="w-full h-full textarea-fill">
        <mat-label>Eredmény</mat-label>

        <textarea
          placeholder=""
          matInput #resultArea readonly
          (click)="resultArea.select()"
          [value]="result()"
        >
        </textarea>
      </mat-form-field>
    </div>
  </div>
</div>
