import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CoolLoadingButtonModule } from '@angular-cool/loading-button';
import { HttpClient } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    MatButton,

    CoolLoadingButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private _formBuilder: FormBuilder,
    private _http: HttpClient,
  ) {
  }

  protected inputForm = this._formBuilder.nonNullable.group({
    position: ['', Validators.required],
    company: ['', Validators.required],
    description: ['', Validators.required],
    requirements: ['', Validators.required],
    benefits: [''],
  });

  protected readonly placeholders = {
    position: `Kérlek add meg a pozíció megnevezését amelyre munkaköri leírást szeretnél készíteni!
(kötelező mező)`,
    company: `Kérlek add meg a cég nevét, vagy azokat a kulcsszavakat amiket mindenképp szeretnél megjeleníteni a vállalatról!
(kötelező mező)
Rövid, figyelemfelkeltő összefüggő szöveges bemutatása a cégnek. A szekció végén lehetőleg megjelenítve a pozíció megnevezését amelybe a jelentkezést várjuk. (max 50-70 karakter)

Fedezd fel a jövő humánügyvitelét a Magyarország piacvezető szolgáltatójának oldalán! Több ezer elégedett ügyfél már profitált innovatív megoldásaikból, melyek között a Kutatás és Fejlesztés területének meghatározó szerepe van. Náluk nem csak a jövő születik meg, hanem az újdonságok is - ők hozzák létre azokat! A hazai piacon bevált megoldásaik megfelelő alapot képeznek egy nemzetközileg is sikeres szerepléshez.

Hozzájuk keresünk most proaktív kollégát az alábbi pozíció betöltésére:

FULLSTACK FEJLESZTŐ`,
    description: `Itt van lehetőséged megadni a legfontosabb feladatokat amelyeket adott munkakörben el kell látni!

(Amennyiben ez a mező üresen marad, akkor egy általános feladat lista kerüljön az output mezőbe amit a pozíció név alapján generál az AI)
Feladat lista leírás (max 5-10 bulletpoint)

  *   Üzleti és fejlesztői oktatással indítanak, amely megalapozza náluk a karriered
  *   Ezt követően 2-4 fős csapatokban fogsz dolgozni
  *   A termékeik technológiai frissítésébe fogsz bekapcsolódni
  *   Az általad megírt kód helyes működésének ellenőrzése
  *   Automata tesztelés`,
    requirements: `Kérlek add meg a megfelelő jelöltel szembeni elvárásokat (végzettség, tapasztalati szint, nyelvtudás, technológiai elvárások, készségek, kompetenciák stb.)
(kötelező mező)
Elvárás lista (max 4-7 bulletpoint)

Sikeres leszel náluk, ha:

  *   Van legalább 3 év releváns szakmai tapasztalatod hasonló területen
  *   Dolgoztál már Angular keretrendszerben
  *   Rendelkezel .NET fejlesztői tapasztalattal
  *   Ismered a TypeScript-et
  *   Használtál már valamilyen ORM-et, illetve IoC Containert
Az alábbi technológiák közül előnyt jelent, ha ismersz néhányat:

  *   ASP.net MVC, WebApi, OData, Autofac, NHibernate, SignalR, FluentValidation, EPPlus`,
    benefits: `Fontos, hogy néhány dolgot kiemelj a pozícióhoz tartozó juttatásokról és, hogy miért érdemes a vállalatnál dolgozni!

(Amennyiben ez a mező üresen marad, akkor egy általános attraktív lista kerüljön az output mezőbe amit generál az AI)
Lista (max 4-7 bulletpoint)


  *   Piacképes alapbért és egyéb juttatásokat
  *   Élményt, szakmai fejlődési lehetőséget, kiszámítható jövőt
  *   Egy családias és jó hangulatú csapatot
  *   Kreatív tevékenységre alkalmas munkakörülményeket ("A" kategóriás irodaház, nagy személyes tér, zöld erkélyek)`,
  };

  protected result = signal<string>('');

  protected isLoading = signal(false);

  protected async generateResultAsync(): Promise<void> {
    if (!this.inputForm.valid) {
      return;
    }

    this.isLoading.set(true);

    try {
      const inputValues = this.inputForm.getRawValue();

      const response = await firstValueFrom(this._http.post<{
        result: string
      }>('https://recruiter-gpt.sandt-gsc.workers.dev/', inputValues));

      this.result.set(response.result);
    } finally {
      this.isLoading.set(false);
    }
  }
}
